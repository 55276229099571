<template>
    <div class="box-history nx-bg-white  ">
        <div class="fw history-hide p-3">
            <div class="fw " v-if="winW < 992">
                <div class="popup-close popout"  v-on:click="$emit('close-popup',false),body.removeAttribute('style')" data-popup="popup-content"><img src="@/assets/img/skin/icon-close-black.svg" alt=""></div>
                <div class="box-welcome box-welcome-m  border-bottom  mb-3">
                    <div class="box-welcome-img">
                        <img src="@/assets/img/skin/icon-user.svg" class="svg" alt="">
                    </div>
                    <div class="box-welcome-cont ms-3">
                        <div class="box-welcome-txt">
                            <h4 class="box-welcome-txt-name h4"><b>คุณ {{ profileData.name }}</b></h4>
                        </div>
                        <div class="box-welcome-credit d-flex align-items-center border-0 p-0">
                            <div class="box-welcome-credit-t gray-lighter me-2"><span>สิทธิ์โหวตของคุณคือ</span></div>
                            <div class="box-welcome-credit-c btn btn-ctr-gd rounded-pill px-3 m-0"> 
                                <span class="box-welcome-credit-ctxt  txt-w-xl" v-if="playStatus == 0">{{ profileData.credit_vote }} สิทธิ์</span>
                                <span class="box-welcome-credit-ctxt  txt-w-xl" v-else>0 สิทธิ์</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-auto text-lg-center  mb-3 history-title">
                <h3 class="gd-hl txt-w-xl">ประวัติการโหวต</h3>
            </div>
            <div class="fw">
                <div class="sec-tab bg-gd">
                    <ul class="justify-content-between">
                        <template  v-for="( history, index ) in  historyData " :key="index">
                            <li class="tab-list" :id="index" v-if="index != 'current_week' "  @click="activateTab(index)" :class="{ active: activeTab === index }">
                                <a href="javascript:void(0)" class="tab-a"><span>{{
                                    historyWeek(index) }}</span> </a>
                            </li>
                        </template>
                        
                    </ul>
                </div>
                <div class="tab-list-item">
                    <template v-if="Object.keys(historyData)">

                        <div v-for="( history, index ) in  historyData  " :key="index" class="list-tab"
                            :class="[activeTab === index ? 'active' : '']">
                            <template v-if="history != ''">
                                <div class="list-item" v-for="( dataWeek, index ) in  history " :key="index">
                                    <template v-for="( region, index ) in  dataWeek " :key="index">
                                       
                                        <div v-if="region.image_cover" class="item-img"> 
                                            <img :src="region.image_cover ? require(`../../assets/img/77-attractions/${replaceExtension(region.image_cover)}`) : ''" alt="" class="fw">

                                        </div>
                                        <div class="item-cont">
                                            <h5 class="txt-w-lg" v-html="region.location_name">
                                            </h5>
                                            <small class="gray-dark">{{ region.location }} จ.{{
                                                region.province_th }} {{ region.region_th}}</small>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <div v-else class="fw text-center border-bottom py-3 no-data">
                                ไม่พบข้อมูลการโหวต
                            </div>
                        </div>

                    </template>
                </div>
                <div class="fw text-center mt-3 btn-logout">
                    <button class="btn btn-ctr-primary px-5 rounded-pill" v-on:click="$emit('close-logout',true)">ออกจากระบบ</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */ 
import { setFunction } from '@/global'
export default {
    props: ['historyData','profileData'],
    data(){
        return{
            winW :window.innerWidth,
            activeTab: 'week_4',
            body:  document.querySelector('body'),
            tabs: [
                { label: 'สัปดาห์ที่ 1', label_en: 'week_1' },
                { label: 'สัปดาห์ที่ 2', label_en: 'week_2' },
                { label: 'สัปดาห์ที่ 3', label_en: 'week_3' },
                { label: 'สัปดาห์ที่ 4', label_en: 'week_4' },
            ],
            windowHeight:0,
            playStatus:0,
            historyData_new: ''
        }
    },
    // async created() {
    //     this.playStatus = await this.$root.isCampaign();
    //     const current_tab = await this.$root.isActiveCurrent_week()
    //     setTimeout(() => {
    //         if (this.playStatus != 0 ) {
    //             if (this.playStatus == 4 ) {
    //                 this.activeTab = 'week_' + (parseInt(current_tab ));
    //                 // console.log(this.activeTab); 
    //             }else{
    //                 if (current_tab == 1) {
    //                     this.activeTab = 'week_1'
    //                 }else{
    //                     this.activeTab = 'week_' + (parseInt(current_tab) - 1);
    //                     // console.log(this.activeTab); 
    //                 }
                   
    //             }
    //         }
            
           
    //     }, 50);
       
    // },
    mounted(){
        this.historyData_new = this.historyData
        this.updateData();
        // if (this.$root.isCloseCampaign() == true) {
        //     this.profileData.credit_vote = 0
        //     if (this.historyData.current_week < 1) {
        //         this.activateTab(this.historyData.current_week - 1)
        //     }
            
        // }
        setFunction();
        
        this.winW = window.innerWidth;
        window.addEventListener('resize', () => {
        this.winW = window.innerWidth;

        // get the active tab element
        const activeTabElement = this.$refs.tab.find(tab => tab.classList.contains('active'));
        const activeTabId = activeTabElement.getAttribute('data-tab-id');
        this.autoTab(activeTabId);
    });

        setTimeout(() => {
            if (document.querySelector('.box-history') != null) {
                document.querySelector('.box-history').classList.add('active');
            }
        }, 100);
        this.calHGiv();
        window.addEventListener('resize', this.handleResize );
    },
    methods:{
        replaceExtension(imageUrl) {
            return imageUrl.replace(/\.jpg$/, '.webp');
        },
        updateData() {
            this.$root.getProfile();
            if (sessionStorage.getItem('USER_INFO')) {
                const decodedDataString = atob(sessionStorage.getItem('USER_INFO'));
                const decodedArray = JSON.parse(decodedDataString);
                this.historyData_new = decodedArray.history;
                // this.current_week = 'week_' + decodedArray.history.current_week;
                document.getElementById('week_'+this.historyData_new.current_week).click()
            }
        },
        handleResize(){
            this.getWindowHeight();
            this.calHGiv();
        },
        historyWeek(num) {
            switch (num) {
                case "week_1":
                    return "สัปดาห์ที่ 1";
                case "week_2":
                    return "สัปดาห์ที่ 2";
                case "week_3":
                    return "สัปดาห์ที่ 3";
                case "week_4":
                    return "สัปดาห์ที่ 4";
            }
        },
        activateTab(index) {
            this.activeTab = index;
            this.calHGiv();
        },
        getWindowHeight() {
            this.windowHeight = window.innerHeight;
        },
        calHGiv() {
            if (document.querySelector('.box-history') != null || document.querySelector('.box-history') != undefined) {
                let wrapH = document.querySelector('.history-hide').offsetHeight;
                if (wrapH > this.windowHeight) {
                    document.querySelector('.history-hide').classList.add('is_scroll');
                }
            }
            
        },
    }
    
}
</script>

<style scoped>
.box-history {
    position: absolute;
    z-index: 100;
    top: 65px;
    right: 30px;
    opacity: 0;transition: all ease-in-out .5s;border-radius: 15px;z-index: 1000;
}
.no-data{min-height: 400;display: flex;align-items:center ;justify-content: center;}
.box-history.active{opacity: 1;}
.tab-list-item{max-width:436px;}
@media (max-width: 1199.98px){
.box-history{top: 62px;}

}
@media (max-width: 991.98px){
    .tab-list-item{max-width:100%;}
    .box-history{
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
       height: 0;overflow: hidden;
       opacity: 1
    }
    .history-hide{padding: 60px 10px 40px 10px !important;}
    .history-hide.is_scroll{max-height:100%;overflow: hidden;overflow-y: scroll;}
    .box-history.active{     height: 100%;z-index: 1000;}
    .box-welcome-m .box-welcome-img{
        width: 55px;
        height: 50px;
    }
    .box-welcome-cont{width: calc(100% - 50px);}
    .box-welcome-txt-name{font-size: calc(1.275rem + .3vw);color: #383838;}
    .box-welcome-credit-t {font-size: 0.9em;}
    .box-history {border-radius: 0;}
    .box-history .popup-close {  background-color: transparent;    top: 10px;right: 10px;}
    .box-history .popup-close svg path{stroke: #7C7C7C;}
    /* .btn-logout{position: absolute;bottom: 20px;left: 0;} */
}
@media (max-width: 767.98px){
    .history-title h3{font-size:2em;}
    .box-welcome{border-radius: 0;    padding: 5px 0 10px 0;}
    .item-cont h5{font-size:1.2em;overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;-webkit-line-clamp: 2;}
    .sec-home-top-rank .scroll-swap .scroll-swap-item{flex:0 0 45%;}
    .tab-list a{font-weight: 300;}
    .tab-list.active a{font-weight: 600;}
    .tab-list-item{padding:0;}
}
@media (max-width: 575.98px){
    .tab-list a{font-size: 0.9em;}
    .box-history .tab-list{padding: 0 10px;}
    .history-title h3{
        font-size: 7vw;
    }
    .item-cont h5 {
        font-size: 4.2vw;
    }
    .box-history .item-img{width: 60px;}
    .box-welcome-credit-c{padding:3px;}
}
@media (max-width: 419.98px){
    .tab-list a{font-size: 0.8em;}
}
</style>