<template>
      <div class="loading-page">
          <div class="counter">
            <img src="@/assets/img/skin/logo.png" alt="">
            
            <div class="load-wrapp">
              <div class="load-3">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
              </div>
            </div>
            <div class="box-content" :class="timeConter == true ? 'is-open':''">
               <p>ขณะนี้มีผู้ใช้งานเว็บไซต์จำนวนมาก <br>กรุณารอสักครู่...</p>
            </div>
              <!-- <hr>
              <small>0%</small> -->
          </div>
      </div>
</template>

<script>
/* eslint-disable */
export default {
    components: {
    },
    data() {
        return {
            timeConter:false
        }
    },
    mounted() {
        this.loadPage();  
        setTimeout(() => {
            this.timeConter = true;
        }, 5000);
    },

    methods: {
        loadPage() {
            // let counter = 0;
            // let c = 0;
            // let i = setInterval(function () {
            //     let h1 = document.querySelector('.loading-page .counter small');
            //     let hr = document.querySelector('.loading-page .counter hr');

            //     h1.innerHTML = c + '%';
            //     hr.style.width = c + '%';
            //     //h1.style.width = c + '%';
            //     //document.querySelector('.loading-page .counter').style.background = 'linear-gradient(to right, #f60d54 ' + c + '%,#0d0d0d ' + c + '%)';

            //     counter++;
            //     c++;

            //     if (counter == 101) {
            //         clearInterval(i);
            //     }
            // }, 50);

        },
    },
};
</script>

<style>
.loadpage-com .box-content{display: none;opacity: 0;}
.loadpage-com:not(.loadApi) .box-content{display: block; min-width: 300px; margin-top: 20px; position: relative;transition: all ease .5s;opacity: 0;}
.loadpage-com:not(.loadApi) .box-content.is-open{opacity: 1;}
.loading-page.loadApi{background: rgb(221 91 107 / 75%);}
/* .loading-page:not(.loadApi) .load-wrapp{display: none;} */
.loading-page .counter .box-content p {font-size: 16px;}
.loading-page {
  background: rgb(221 91 107 );
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
}

.loading-page .counter {
  text-align: center;
}

.loading-page .counter p {
  font-size: 40px;
  font-weight: 100;
  color: var(--color-white);
}

.loading-page .counter small {
  color: white;
  margin-top: -10px;
}

.loading-page .counter img{width: 40%;display: block;margin: 0 auto;margin-bottom: 20px;}
.loading-page .counter hr {
  background: var(--color-white);
  border: none;
  height: 3px;opacity: 1;margin-bottom: 0;
}

.loading-page .counter {
  position: relative;
  width: 300px;
}

.loading-page .counter small.abs {
  position: absolute;
  top: 0;
  width: 100%;
}

.loading-page .counter .color {
  width: 0px;
  overflow: hidden;
  color: #f60d54;
}


/* =Elements style
---------------------- */


.load-wrapp p {
  padding: 0 0 20px;
}
.load-wrapp:last-child {
  margin-right: 0;
}

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #fff; margin: 0 10px;
}

.ring-1 {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  padding: 10px;
  border: 7px dashed #4b9cdb;
  border-radius: 100%;
}

.ring-2 {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  border: 4px solid #4b9cdb;
  border-radius: 100%;
}

.ball-holder {
  position: absolute;
  width: 12px;
  height: 45px;
  left: 17px;
  top: 0px;
}

.ball {
  position: absolute;
  top: -11px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #4282b3;
}

.letter-holder {
  padding: 16px;
}

.letter {
  float: left;
  font-size: 14px;
  color: #777;
}

.square {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: #4b9cdb;
}

.spinner {
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.bubble-1,
.bubble-2 {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #4b9cdb;
}

.bubble-2 {
  top: auto;
  bottom: 0;
}

.bar {
  float: left;
  width: 15px;
  height: 6px;
  border-radius: 2px;
  background-color: #4b9cdb;
}

/* =Animate the stuff
------------------------ */

.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}
@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}


</style>
