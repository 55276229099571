
const state = {
    testData: [
      {
        showLogintest: false,
        dataChecktest: '',
        location_ID: null
      }
    ]
  }
  
  const mutations = {
    updateTestData(state, newData) {
      state.testData = newData
    }
  }
  
  
  const actions = {
    updateData({ commit }, data) {
      // // console.log('data', data);
      commit('updateTestData', data)
    }
  }
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations
  };
  