import { createApp ,ref} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/css/main.css'

const app = createApp(App)
app.use(router)
app.use(store)

const gaInitialized = ref(false); // Reactive variable to track GA initialization
app.config.globalProperties.$gaInitialized = gaInitialized;

const cookieConsentActive = ref(false);
app.config.globalProperties.$cookieConsentActive = cookieConsentActive;

function cookieConsent() {
  var intervalCheck = null;
  intervalCheck = setInterval(() => {
    const cookies = document.cookie.split(';');
    let pdpa_consent = '';

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('pdpa_consent_unseen=')) {
        pdpa_consent = cookie.split('=')[1];
        break;
      }
    }

    if (pdpa_consent === 'active') {
      app.use(VueGtag, {
        // config: { id: 'G-NEX569WVWF' }
        config: { id: 'G-MFNGCHQ8WL' },
        onReady: () => {
          gaInitialized.value = true;
        }
      });
      // console.log('init ga');
      if (gaInitialized.value == true) {
        // console.log('GA initialized: true ||| = ', gaInitialized.value);
        clearInterval(intervalCheck);
      }
     
    }
  }, 5000);
}


const cookies = document.cookie.split(';');
let pdpa_consent = '';

for (let i = 0; i < cookies.length; i++) {
  const cookie = cookies[i].trim();
  if (cookie.startsWith('pdpa_consent_unseen=')) {
    pdpa_consent = cookie.split('=')[1];
    break;
  }
}
if (pdpa_consent === 'active') {
app.config.globalProperties.clickTracking = function (name, value) {
  // console.log(name, value);
  this.$gtag.event(name, {
    event_category: name,
    event_label: value,
    value: 1
  });
};
}

app.mount('#app');

export { app, cookieConsent };




if (pdpa_consent === 'active') {
  app.use(VueGtag, {
    config: { id: 'G-MFNGCHQ8WL' },
    onReady: () => {
      gaInitialized.value = true;
    }
  });
}

// const cookies = document.cookie.split(';');
//     let pdpa_consent = '';

//     for (let i = 0; i < cookies.length; i++) {
//       const cookie = cookies[i].trim();
//       if (cookie.startsWith('pdpa_consent_unseen=')) {
//         pdpa_consent = cookie.split('=')[1];
//         break;
//       }
//     }

//     if (pdpa_consent === 'active') {
//       // console.log('test');
// if (gaInitialized.value != true) {
//   setInterval(() => {
//     // console.log('GA initialized:', gaInitialized.value);
//   }, 5000);
// }
//     }