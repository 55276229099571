/* eslint-disable */

export function setFunction() {
	checkResize();
	svg();
    
}

export function initSvg() {
	svg();
    console.log('svg init');
}

export function bannerSlide(){
    bannerSide();
}
window.addEventListener("load", () => {
	svg();
    var tag = document.createElement("script");
	tag.src = "https://www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName("script")[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
});

document.addEventListener("DOMContentLoaded", function () {
	checkResize();
	svg();
});



window.addEventListener("resize", () => {
	checkResize();
});

function checkResize() {
	var winW  ;
}


function svg() {
	document.querySelectorAll("img.svg").forEach(function (img) {
		var imgID = img.getAttribute("id");
		var imgClass = img.getAttribute("class");
		var imgURL = img.getAttribute("src");
		var xhr = new XMLHttpRequest();
		xhr.open("GET", imgURL, true);
		xhr.onreadystatechange = function () {
			if (this.readyState === 4 && this.status === 200) {
				var data = xhr.responseXML;
				var svg = data.querySelector("svg");
				if (typeof imgID !== "undefined") {
					svg.setAttribute("id", imgID);
				}
				if (typeof imgClass !== "undefined") {
					svg.setAttribute("class", imgClass + " replaced-svg");
				}
				svg.removeAttribute("xmlns:a");
				if (
					!svg.getAttribute("viewBox") &&
					svg.getAttribute("height") &&
					svg.getAttribute("width")
				) {
					svg.setAttribute(
						"viewBox",
						"0 0 " +
						svg.getAttribute("height") +
						" " +
						svg.getAttribute("width")
					);
				}
				// img.parentNode.replaceChild(svg, img);
				if (img.parentNode) {
					img.parentNode.replaceChild(svg, img);
				  }
                  
			}
		};
		xhr.setRequestHeader(
			"Content-Type",
			"application/x-www-form-urlencoded; charset=UTF-8"
		);
		xhr.send();
	});
}

export function  playVideo(){
    if (document.querySelector(".click-to-play-video") !== null) {

        document.querySelector(".click-to-play-video").addEventListener("click", function (event) {
            //// console.log('test');
            
            var dataState = event.target.closest(".swiper-wrapper");
            var dataVideo_ganeral = event.target.getAttribute("data-video");
            var section_type = event.target.getAttribute("section_type");
            var data_code = event.target.getAttribute("data-code");
            var time_update_interval = 0;
            var str = "player_ganeral_" + data_code + "_" + section_type;

            var videoPlayer = new window.YT.Player(str, {
                width: "100%",
                height: "100%",
                videoId: dataVideo_ganeral,
                playerVars: {
                    autoplay: 1,
                    controls: 1,
                    showinfo: 0,
                    modestbranding: 0,
                    iv_load_policy: 3,
                },
                events: {
                    onReady: function (event) {
                        event.target.playVideo();
                       
                    },
                    onStateChange: function (event) {

                        clearInterval(time_update_interval);
                        time_update_interval = setInterval(function () {
                            if (event.data == window.YT.PlayerState.ENDED) {
                                event.target.destroy();
                            }
                        }, 100);
                    },
                },
            });
        });
    }
}


//////////////////////////////
/////// BANNER SLIDE /////////
/////////////////////////////
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

var st_banner = "";
function bannerSide() {
	var slideOnStop = 1;
	var dataStateDiv = "1";

	var playerBanner;

    st_banner = new Swiper(document.querySelector(".box-banner-slide .banner_slide"), {
        loop: false,
        speed: 1000,
        autoplay: {
            delay: 10000,
            disableOnInteraction: true,
        },
        pagination: {
            el: document.querySelector(".box-banner-slide .swiper-pagination"),
            clickable: true,
        },
        breakpoints: {
            991: {
                slidesPerView: 1,
                simulateTouch: true,
            },
        },
    });
    st_banner.on("slideChange", function (e) {
        dataStateDiv = e.wrapperEl.getAttribute("data-state");
        // if (dataStateDiv == 0) {
        //     e.wrapperEl.setAttribute("data-state", "1");
        //     var youtubeIframe = e.wrapperEl.querySelectorAll(".video-youtube-0");
        //     youtubeIframe.forEach((element) => {
        //         var youtubeIframeId = element.children[0].children[0].children[0].children[0].children[0];
        //         var dataVideo_ganeral = youtubeIframeId.getAttribute("data-video");
        //         var section_type = youtubeIframeId.getAttribute("section_type");
        //         var data_code = youtubeIframeId.getAttribute("data-code");
        //         var str = "player_ganeral_" + data_code + "_" + section_type;
        //         document.querySelector("#" + str)
        //             .contentWindow.postMessage(
        //                 '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        //                 "*"
        //             );
        //     });
        //     dataStateDiv = e.wrapperEl.getAttribute("data-state");
        // }
        if (dataStateDiv == 0) {
            e.wrapperEl.setAttribute("data-state", "1");
        }
    });

	if (document.querySelector(".click-to-play-video") !== null) {

		document.querySelector(".click-to-play-video").addEventListener("click", function (event) {
            //// console.log('test');
            
            var dataState = event.target.closest(".swiper-wrapper");
            var dataVideo_ganeral = event.target.getAttribute("data-video");
            var section_type = event.target.getAttribute("section_type");
            var data_code = event.target.getAttribute("data-code");
            var time_update_interval = 0;
            var str = "player_ganeral_" + data_code + "_" + section_type;

            var videoPlayer = new window.YT.Player(str, {
                width: "100%",
                height: "100%",
                videoId: dataVideo_ganeral,
                playerVars: {
                    autoplay: 1,
                    controls: 1,
                    showinfo: 0,
                    modestbranding: 0,
                    iv_load_policy: 3,
                },
                events: {
                    onReady: function (event) {
                        slideOnStop = 0;

                        event.target.playVideo();
                        if (dataState.getAttribute("data-state") == 1) {
                            dataState.setAttribute("data-state", "0");
							// // // console.log(st_banner && st_banner.autoplay);
							if (st_banner != undefined) {
								// // console.log(st_banner.autoplay);
                                console.log('st_banner.autoplay',st_banner.autoplay);
                                console.log('stop',st_banner.autoplay.stop());
								st_banner.autoplay.stop();
                             
								st_banner.autoplay.running = false;
							}
                        }
                    },
                    onStateChange: function (event) {
                        console.log(event);
                        if (st_banner != undefined) {
                            st_banner.autoplay.stop();
                        }

                        clearInterval(time_update_interval);
                        time_update_interval = setInterval(function () {
                            if (event.data == window.YT.PlayerState.ENDED || dataState.getAttribute("data-state") == 1) {
                                
                                // if (event.data == window.YT.PlayerState.ENDED) {
                                //     event.target.destroy();
                                // }
                                // if (dataState.getAttribute("data-state") == 1) {
                                //     event.target.pauseVideo();
                                // }

                                if (st_banner != undefined) {
                                    st_banner.autoplay.start();
                                }
                                dataState.setAttribute("data-state", "0");
                                clearInterval(time_update_interval);
                                event.target.destroy();
                            }
                        }, 100);
                    },
                },
            });
        });
	}

	// function onPlayerReady(event) {
	// 	event.target.playVideo();
	// 	// // // console.log(event.target);
	// }
	// function onPlayerStateChange_banner(event) {
	// 	if (event.data === window.YT.PlayerState.ENDED) {
	// 		document.querySelector(".box-bannervideo").style.display = "none";
	// 		st_banner.autoplay.start();
	// 		playerBanner.destroy();
	// 		document
	// 			.querySelector(".banner-video .click-to-play-video")
	// 			.classList.remove("hidePreview");
	// 	}
	// }

	// function onPlayerStateChange_General(event) {
	// 	if (event.data === window.YT.PlayerState.ENDED) {
	// 		player_General.destroy();
	// 		document.querySelector(".General-video .click-to-play-video").classList.remove("hidePreview");
	// 	}
	// }


    
}

