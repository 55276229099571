import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PromotionView from '../views/PromotionView.vue';
const routes = [
  {
    path: '/close-home',
    name: 'home',
    component: HomeView,
    meta: {
        title: 'Home Page',
        showFooter: true
    }
  },
  {path: '/rules-reward',name: 'rules-reward',component: () => import('../views/RulesAndRewardView.vue')},
  {
    path: '/attractions',
    name: 'attractions',
    component: () => import('../views/AttractionsView.vue'),
  },
  // {path: '/25attractions',name: '25attractions',component: () => import('../views/25AttractionsView.vue')},
  // {path: '/promotion',name: 'promotion',component: () => import('../views/PromotionView.vue')},
  {
    path: '/promotion',
    name: 'promotion',
    component: PromotionView,
    beforeEnter: (to, from, next) => {
      // // console.log(to);
        // to.matched[0].components.default.methods.getPromotions()
        to.matched[0].components.default.computed

      next();
    },
  },

  {path: '/announcement',name: 'announcement',component: () => import('../views/AwardAnnouncementView.vue')},


  {path: '/',name: 'new-home',component: () => import('../views/list-detail/NewHomeView.vue')},





  // 25 Unseen Router 
  { path: '/phetchabun', name: 'phetchabun', component: () => import('../views/list-detail/phetchabunDetailView.vue'), },
  { path: '/nakhon-sawan', name: 'nakhon-sawan', component: () => import('../views/list-detail/nakhonsawanDetailView.vue'), },
  { path: '/lampang', name: 'lampang', component: () => import('../views/list-detail/lampangDetailView.vue'), },
  { path: '/lamphun', name: 'lamphun', component: () => import('../views/list-detail/lamphunDetailView.vue'), },
  { path: '/nan', name: 'nan', component: () => import('../views/list-detail/nanDetailView.vue'), },
  
  { path: '/chaiyaphum', name: 'chaiyaphum', component: () => import('../views/list-detail/chaiyaphumDetailView.vue'), },
  { path: '/loei', name: 'loei', component: () => import('../views/list-detail/loeiDetailView.vue'), },
  { path: '/sisaket', name: 'sisaket', component: () => import('../views/list-detail/sisaketDetailView.vue'), },
  { path: '/nakhon-ratchasima', name: 'nakhon-ratchasima', component: () => import('../views/list-detail/nakhonratchasimaDetailView.vue'), },
  { path: '/maha-sarakham', name: 'maha-sarakham', component: () => import('../views/list-detail/mahasarakhamDetailView.vue'), },

  { path: '/saraburi', name: 'saraburi', component: () => import('../views/list-detail/saraburiDetailView.vue'), },
  { path: '/petchaburi', name: 'petchaburi', component: () => import('../views/list-detail/phetchaburiDetailView.vue'), },
  { path: '/kanchanaburi', name: 'kanchanaburi', component: () => import('../views/list-detail/kanchanaburiDetailView.vue'), },
  { path: '/ratchaburi', name: 'ratchaburi', component: () => import('../views/list-detail/ratchaburiDetailView.vue'), },
  { path: '/prachuap-khiri-khan', name: 'prachuap-khiri-khan', component: () => import('../views/list-detail/prachuapDetailView.vue'), },
  
  { path: '/surat-thani', name: 'surat-thani', component: () => import('../views/list-detail/suratthaniDetailView.vue'), },
  { path: '/ranong', name: 'ranong', component: () => import('../views/list-detail/ranongDetailView.vue'), },
  { path: '/phuket', name: 'phuket', component: () => import('../views/list-detail/phuketDetailView.vue'), },
  { path: '/nakhon-si-thammarat', name: 'nakhonsithammarat', component: () => import('../views/list-detail/nakhonsithammaratDetailView.vue'), },
  { path: '/satun', name: 'satun', component: () => import('../views/list-detail/satunDetailView.vue'), },
  
  { path: '/chacheangsao', name: 'chacheangsao', component: () => import('../views/list-detail/chacheangsaoDetailView.vue'), },
  { path: '/chonburi', name: 'chonburi', component: () => import('../views/list-detail/chonburiDetailView.vue'), },
  { path: '/nakhonnayok', name: 'nakhonnayok', component: () => import('../views/list-detail/nakhonnayokDetailView.vue'), },
  { path: '/rayong', name: 'rayong', component: () => import('../views/list-detail/rayongDetailView.vue'), },
  { path: '/sakaew', name: 'sakaew', component: () => import('../views/list-detail/sakaewDetailView.vue'), },


  

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFound/NotFound404View.vue'),
  },

  {
      path: '/404',
      name: '404',
      component: () => import('../views/NotFound/NotFound404View.vue'),
      beforeEnter: (to, from, next) => {
          to.meta.title = 'error 404'
          next()
      }
  },
  {
      path: '/error',
      name: 'error',
      component: () => import('../views/NotFound/NotFound404View.vue'),
      meta: { requiresErrorCheck: true }
  }



  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
