<template>
    <Transition>

        <div class="global-popup section-popup auth-popup pop-fade popup-location-desc">

            <div class="popup-bg" v-on:click="$emit('close-popup'), body.removeAttribute('style')"></div>
            <div class="popup-container">
                <div class="popup-close popout" v-if="winW >= 768" data-popup="popup-content"
                    v-on:click="$emit('close-popup'), body.removeAttribute('style')"><img
                        src="@/assets/img/skin/icon-close.svg" alt=""></div>

                <div class="box-popup">

                    <div class="popup-body scroll" >
                        <div class="popout position-relative btn-icon py-md-4 py-2" v-if="winW < 768"
                            data-popup="popup-content"
                            v-on:click="$emit('close-popup'), body.removeAttribute('style')"><span
                                class="justify-content-end"><img src="@/assets/img/skin/icon-back.svg" class="svg"
                                    alt=""><span>กลับ</span></span></div>

                        <div class="row">
                            <div class="col-12 col-md-5">
                                <div class="thumb-img">
                                    <img v-if="promotionArray[0].image != ''" :src="require(`../../assets/img/promotion/${promotionArray[0].image}`)" alt="" class="fw">
                                </div>
                            </div>
                            <div class="col-12 col-md-7 popup-right-content position-relative">
                                <div class="box-location-group-head">
                                    <div class="box-location-head">
                                        <h3 class="primary txt-w-xl">{{promotionArray[0].name}}</h3>
                                    </div>
                                </div>

                               

                                 <!-- <div class="pop-travel-content gray-dark box-content" :style="`${styleH}:${contH}px`">
                                    <p>ห้องพักพร้อมอาหารเช้า ราคาพิเศษ</p>
                                    <ul>
                                        <li>Bay View Suite  ราคาคืนละ 5,000 บาท สุทธิ</li>
                                        <li>BCape Panwa Suite  ราคาคืนละ 5,800 บาท สุทธิ</li>
                                        <li>BSea View Suite  ราคาคืนละ 6,500 บาท สุทธิ</li>
                                        <li>BPool Pavilion ราคาคืนละ 7,600 บาท สุทธิ</li>
                                        <li>BBay View Pool Villa ราคาคืนละ  9,500 บาท สุทธิ</li>
                                        <li>BOcean View Pool Villa ราคาคืนละ  11,500 บาท สุทธิ</li>
                                    </ul>
                                    <p>สิทธิประโยชน์เพิ่มเติมสำหรับลูกค้าที่พักห้อง  BAY VIEW POOL VILLA และ OCEAN VIEW POOL VILLA :</p>
                                    <ul>
                                        <li>ไวน์จำนวน 1 ขวดต่อการเข้าพัก</li>
                                        <li>บริการซักหรือรีดผ้าจำนวน 8 ชิ้นต่อวัน</li>
                                        <li>ชุดน้ำชายามบ่ายพร้อมอาหารว่างสำหรับ 2 ท่าน (1 ชุดต่อการเข้าพัก)</li>
                                        <li>เครื่องดื่มค็อกเทลหรือม็อกเทล 1 แก้วต่อท่านต่อการเข้าพักที่ The Pool Terrace 13:00 - 18:00 น. / Sun & Moon ระหว่างเวลา 18:00 – 19:00 น.</li>
                                    </ul>
                                </div> -->

                                <div class="pop-travel-content gray-dark box-content" :style="`${styleH}:${contH}px`">
                                    <p v-html="promotionArray[0].description"></p>
                                </div>

                                <div class="location-vote-popup d-block float-start">
                                    <div  class="gray-dark mb-3 fw btn-icon txt-location txt-w-og box-contact-location">
                                        <span class="justify-content-start"><img src="@/assets/img/skin/icon-blank.svg"
                                                class="svg" alt=""><span v-html="promotionArray[0].link_out"></span>
                                        </span>
                                    </div>
                                    <!-- <div v-if="cont.tel != ''" class="gray-dark mb-3 fw btn-icon txt-location txt-w-og box-contact-location">
                                        <span class="justify-content-start"><img src="@/assets/img/skin/icon-phone.svg"
                                                class="svg" alt=""><span v-html="cont.tel"></span>
                                        </span>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
/* eslint-disable */
import { setFunction } from '@/global'
export default {
    props: ['promotionArray'],
    data() {
        return {
            contH: 0,
            body: document.querySelector('body'),
            winW: window.innerWidth,
            windowHeight: 0,
            styleH: 'min-height',
            imageOfsize: false,
            imgH: 0,
            imgB: 0
        }
    },
    mounted() {

        this.getWindowHeight();
        window.addEventListener('resize', this.handleResize );
        setTimeout(() => {
            setFunction();
        }, 50);
     
        this.winW = window.innerWidth;
        window.addEventListener('resize', () => {
            this.winW = window.innerWidth;
        });


        this.body.style.overflow = 'hidden'; 

        setTimeout(() => {
            document.querySelector('.pop-fade').classList.add('active');
        }, 150);

        if (this.winW >= 768) {
            setTimeout(() => {
                this.calHGiv();
            }, 120);
        } else {
            this.contH = 'unset';
        }
        
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize(){
            setFunction();
            this.getWindowHeight();
        },
        getWindowHeight() {
            this.windowHeight = window.innerHeight;
        },
        calHGiv() {
            if (document.querySelector('.thumb-img') != null || document.querySelector('.thumb-img') != undefined) {
                let wrapH = document.querySelector('.thumb-img').offsetHeight;
                let txtHeader = document.querySelector('.box-location-group-head').offsetHeight;
                let txtLocation = document.querySelector('.txt-location').offsetHeight;

                let VBtnH = document.querySelector('.location-vote-popup').offsetHeight;
                this.contH = wrapH - (txtHeader + txtLocation + VBtnH + 20);


                let popupBody = document.querySelector('.popup-location-desc .popup-body ').offsetHeight;
                //// console.log('popupBody',popupBody , (this.windowHeight - 60));
                if (popupBody > (this.windowHeight - 60)) {
                    const totalPadding = 90;
                    this.styleH = 'max-height';
                    document.querySelector('.pop-travel-content').classList.add('is-scroll');
                    this.contH = (this.windowHeight - 100) - (totalPadding + txtHeader + txtLocation + VBtnH);
                }
            }
            
        },

   
}


}
</script>


<style>
.gallery-top .swiper-button-next:after,
.gallery-top .swiper-rtl .swiper-button-prev:after {
    content: none !important;
}

.gallery-top .swiper-button-prev:after,
.gallery-top .swiper-rtl .swiper-button-next:after {
    content: none !important;
}

.pop-fade {
    opacity: 0;
    transition: all ease-in-out .5s;
}

.pop-fade.active {
    opacity: 1;
}

.auth-popup .popup-container {
    border-radius: 20px;
    width: 1060px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.thumb-img{overflow: hidden;border-radius: 10px;}

.gallery .gallery-top {
    padding: 5px;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}
.box-lcation-head{width: calc(100% - 20px);}
.gallery .gallery-top .swiper-slide {
    overflow: hidden;
    border-radius: 10px;
    height: 380px;
}
.popup-location-desc .col-left {
    margin-right: 0;
}
.gallery-thumbs .swiper-wrapper{transform: translate3d(0px, 0, 0) ; }

.is-scroll {
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 20px;
}

.popup-body {
    padding: 45px;
}

.swiper-container {
    overflow: hidden;
    position: relative;
    height: auto;
}

.gallery {
    position: relative;
    height: auto
}

.gallery .swiper-container {
    height: auto;
}

.auth-popup .swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-popup .swiper-slide img {
    width: 100%;
    height: auto;
}

/* .gallery-thumbs {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 80px;
	z-index: 10;
} */

.btn-icon.txt-location > span svg {
    height: 20px;
    width: 20px;
}

.gallery-thumbs .swiper-slide {
    /* width: 100px ;*/
    /* min-height: 100px;  */
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
}

.gallery-top .swiper-slide img {
    object-fit: contain;height: 400px;
}
/* .gallery-thumbs .swiper-wrapper{transform: translate3d(0px, 0, 0) !important;} */

.gallery-top .swiper-button-next svg path,.gallery-top .swiper-button-prev svg path{stroke: #EC268F;}

.gallery-thumbs .slideActive_custom{
    border: 2px solid #EC268F;

}

.gallery-thumbs .swiper-slide img {
    float: left;
    width: 100%;
    height: 100%;
}

.auth-popup .location-vote-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    /* width: calc(100% - ((var(--bs-gutter-x) * .5)*2)); */
    border-top: 1px solid #E4E4E4;
    padding-top: 15px;
    margin-top: 15px;
    /* padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5); */
    /* margin-left: calc(var(--bs-gutter-x) * .5); */
}

.auth-popup .isvote {
    pointer-events: none;
}

.box-contact-location a{text-decoration: underline;color: var(--color-gray-dark); font-weight: 400;}
.auth-popup .disableVote svg {
    fill: #B1B1B1;
    pointer-events: none;
}

/* .disableVote svg path{fill: #B1B1B1 !important;} */
.auth-popup .card-like.disableVote svg .path-2 {
    fill: #B1B1B1;
}

.auth-popup .disableVote {
    color: #B1B1B1;
    background-color: #E2E2E2;
    border: 2px solid #E2E2E2;
    pointer-events: none;
}

.auth-popup .isvote svg {
    fill: #fff;
}

.auth-popup .isvote svg path {
    fill: #fff !important;
}

.auth-popup .isvote {
    color: #fff;
    background-color: var(--color-primary);
}

.auth-popup .card-like.isvote svg .path-1 {
    fill: #fff;
}

.popup-location-desc {
    z-index: 1000 !important;
}

.popup-location-desc .group-share {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-location-desc .box-share {
    display: flex;
    align-items: center;
}

.box-share a {
    cursor: pointer;display:flex;align-items:center;
}

.box-share a svg {
    fill: #4267B2;
    transition: all ease-in .2s;
}

.box-share a:hover svg {
    opacity:0.5
}
.box-location-head{width: calc(100% - 20px);}




@media (max-height: 650.98px) {
    .auth-popup .popup-container{width: 900px;}
}

@media (max-width: 1199.98px) {
    .popup-location-desc .popup-container{width: 95vw;}
    .gallery-top .swiper-slide img{height: 280px;}
    .gallery .gallery-top .swiper-slide { min-height:34vw;}
    .box-share a svg {
        fill: #4267B2;
    }
}

@media (max-width: 991.98px) {
    .auth-popup .popup-container {
        width: 90vw;
    }

    .popup-location-desc .popup-close {
        top: 10px;
        right: 10px;
    }

    .box-location-head {
        font-size: 0.9em;
    }

    .auth-popup .location-vote-popup {
        padding-top: 15px;
    }

    .txt-location {
        font-size: 0.9em;
    }

    .pop-travel-content {
        font-size: 0.9em;
    }

    .gallery .gallery-top {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .auth-popup .popup-container {
        width: 100vw;
    }
    .box-location-head{  width: 100%;}
    .box-location-group-head {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    /* .gallery-top .swiper-slide img{height: 350px;} */

    .gallery .gallery-top {
        width: 100%;
    }

    .popup-location-desc .popup-body.scroll {
        max-height: calc(100vh - 75px);
        overflow: scroll;
        padding: 10px 45px 40px;
    }

    .box-vote-sticky {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background-color: #fff;
        border-top: 1px solid #eee;
    }

    .popup-location-desc.auth-popup .popup-container {
        border-radius: 0;
        height: 100%;
        top: 0;
        transform: translate(-50%, 0);
    }

    .pop-travel-content {
        overflow: visible;
        padding-right: 0;
    }

    .popup-location-desc .popup-bg {
        display: none;
    }

    .popup-location-desc .col-left {
        margin-right: 0;
        padding-right: 0;
    }

    .popup-location-desc.auth-popup .location-vote-popup {
        position: relative;
        padding: 20px 0 0;
        margin: 20px 0 0 0;
        width: 100%;
        justify-content: center;
    }

    .box-location-group-head {
        margin-top: 20px;
    }
    .gallery .gallery-top .swiper-slide { min-height:auto;height: auto;} 
    .thumb-img{width: 100%;}
}

@media (max-width: 575.98px) {
  
    .gallery-thumbs .swiper-slide{border-radius:8px}

    /* .gallery-top .swiper-slide img{height: 340px;} */
    .popup-location-desc .popup-body.scroll {
        padding: 0 20px 20px;
        padding-bottom:75px;
    }

    .popup-body {
        padding: 45px 5px;
    }

    .popup-location-desc .col-left {
        padding-right: calc(var(--bs-gutter-x) * .5);
    }
    
}

@media (max-width: 419.98px) {
    /* .gallery-top .swiper-slide img{height: 310px;} */
}</style>

