<template>
    <Transition>
        <div class="global-popup section-popup voteResult vote-error">
            <div class="popup-bg" @click="refesh()"></div>
            <div class="popup-container">
                <div class="popup-close popout"  @click="refesh()" data-popup="popup-content"><img src="@/assets/img/skin/icon-close.svg" alt=""></div>
                <div class="box-popup">
                    <div class="popup-body ">
                        <div class="row justify-content-center">
                            <div class="col-10">
                                <div class="pop-cont-overlap">
                                    <div class="img-overlap">
                                        <img src="@/assets/img/skin/icon-error.png" alt="" class="fw">
                                    </div>
                                    <div class="fw text-center cont-vote">
                                        <h3 class="txt-w-lg primary">คุณได้โหวตภาคนี้ไปแล้ว</h3>
                                        <p class="txt-w-l">กรุณาลองใหม่อีกครั้ง</p>
                                        <a class="mt-4 btn btn-ctr-primary  rounded-pill px-4" href="javascript:void(0)" @click="refesh()">โหวตภาคอื่นๆ</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>
    
<script>
/* eslint-disable */
import { setFunction } from '@/global'
    export default {
        data() {
            return {
            }
        },
        mounted(){
            setFunction();
            this.$root.updateData();
        },
        methods: {
            
            refesh(){
                window.location.reload();
            }
        }
    
    }
    </script>
    
    
<style scoped>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    .popup-container { width: 500px;overflow: visible;border-radius: 20px;}
    .pop-cont-overlap{width: 100%;display: block;}
    .vote-error .img-overlap{position: relative;width: 230px;padding: 5px;margin: 0 auto;display: block;margin-top:-160px;}
    .section-popup:not(.voteResult) .img-overlap{ background-color: #fff; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);border-radius: 15px;}
    .img-overlap img{border-radius: 10px;    float: none;display: block;}
    
    /* .voteResult .img-overlap{
        top: -80px;
        margin: 0 auto;
        margin-bottom: -80px;
    } */
    .section-popup{color:#7C7C7C;}
    .popup-close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: #EC268F;
        color: #fff;
    }
    .section-popup{z-index: 1001;}
    .cont-login{display: none;}
    .cont-register{display:none;}
    .login-api .cont-login{display: block;}
    .login-api .cont-vote{display: none;}
   .register-api .cont-register{display: block;}
   .register-api .cont-vote{display: none;}

@media (max-width: 767.98px){
    .popup-container {
        width: 90vw;
        overflow: visible;
        /* top: auto;
        bottom: 0;
        transform: translate(-50%,0);
        border-radius: 10px 10px 0 0; */
    }
    .credit-result {
    font-size: 1.3em;
    }
    .credit-result span {
        width: 40px;
        height: 40px;
    }
    .img-overlap{
        margin: 0 auto;
        margin-bottom: -160px;
        width: 230px;
    }
}

    </style>
    